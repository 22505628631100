import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import ResultsView from 'ToolboxComponents/webapp/pages/results-view/results-view';
import Text from 'ToolboxComponents/commons/texts/text/text';



const ClustersView = ({ products, addFavorite, deleteFavorite }) => {
  return (
    <ResultsView
      products={products}
      hasFilters={false}
      isCluster={true}
      targetPage='cluster'
      specialHeader= {{
        title: <Text path={`page.clusters.title`} data={{clusterCount: products.length}}/>,
        teaser: <Text path={`page.clusters.description`} />,
        highlight: <Text path={`page.majors.enjoy`} />
      }}
    />
  );
};

ClustersView.propTypes = {
  products: PropTypes.array.isRequired
};

export default ClustersView;
