import React, { useContext, useEffect, useState  } from 'react';
import { Helmet } from "react-helmet";
import { LanguageContext, WhiteLabelContext, GeolocationContext } from "ToolboxUtils/web/context/context";
import ClustersView from 'ToolboxComponents/webapp/pages/clusters-biennale-view/clusters-biennale-view';
import Loader from 'ToolboxComponents/commons/loader/loader';
import Text from 'ToolboxComponents/commons/texts/text/text';
import useApiChuchoteurs from 'ToolboxUtils/web/hooks/use-api-chuchoteurs';
import useIsContentLoaded from 'ToolboxUtils/web/hooks/use-is-content-loaded';

const ClustersBiennale = props => {
  const [language] = useContext(LanguageContext).usePath();
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [coordinates] = useContext(GeolocationContext).usePath('coordinates');

  const clusters = useApiChuchoteurs.get(`/products`, {
    params: { 
      filter: 'clusters-biennale', 
      language: language, 
      whiteLabelId: whiteLabel.id,
      isInZone: whiteLabel.isInZone,
      longitude: coordinates.longitude,
      latitude: coordinates.latitude
    }
  });
  
  useIsContentLoaded(clusters.isComplete);

  return (
    <>
      {clusters.isComplete
        ? <ClustersView products={clusters.data} />
        : <Loader />
      }
    </>
  );
}

export default ClustersBiennale;
